import 'lazysizes';
import 'lazysizes/plugins/parent-fit/ls.parent-fit';
import Slider from './components/Slider';
import Popup from './components/Popup';
import Splitting from 'splitting';
import Video from './components/Video';
import Menu from './components/Menu';
import StackCards from './components/StackCards';

import '@lottiefiles/lottie-player';

export class Alfred {
	constructor() {
		// Promises waarop gewacht  wordt. Standaard wordt er gewacht op het 'DOMContentLoaded' event
		Promise.all([Alfred.domReady()]).then(() => this.init());
	}

	init() {
		Slider.init();
		Popup.init();
		Video.init();
		StackCards.init();

		new Menu();


		Splitting();

		function scrollTo(element) {
			window.scroll({
				behavior: 'smooth',
				left: 0,
				top: element.offsetTop,
			});
		}

		document.querySelector('.button--anchor').addEventListener('click', () => {
			scrollTo(document.getElementById('wave'));
		});

		document.dispatchEvent(new Event('bless'));
	}

	static domReady() {
		return new Promise((resolve) => document.addEventListener('DOMContentLoaded', resolve));
	}
}

new Alfred();
import scrollLock from 'scroll-lock';

export default class Popup {
	static init() {
		const popupModals = document.querySelectorAll('.popup');
		popupModals.forEach(popup => new Popup(popup) );
    }

    constructor(popup) {
        this.popup = popup;

        if (!this.popup) {
            return;
        }

		this.closeBtn = document.querySelector('.js-close-modal');

		this.popupOpen = true;

		if (window.sessionStorage) {

			let visited = sessionStorage.getItem('visited');

			if (visited) {
				this.popupOpen = false;
			}
		}

        this.closeBtn.addEventListener('click', e => {
			this.closePopup();
		});

        this.popup.addEventListener('click', e => {
            const isOutside = !e.target.closest('.modal-inner');

            if (isOutside) {
                this.closePopup();
            }
        });

        let FocusableElements = this.popup.querySelectorAll('input, button');

		const firstFocusableEl = FocusableElements[0];
		const lastFocusableEl = FocusableElements[FocusableElements.length - 1];

		window.addEventListener('keydown', e => {
			if (e.key === 'Escape') {
				this.closePopup();
			}

			if (e.key === 'Tab' || e.key === 9) {
				if ( e.shiftKey ) /* shift + tab */ {
					if (document.activeElement === firstFocusableEl) {
						lastFocusableEl.focus();
						e.preventDefault();
					}
				} else /* tab */ {
					if (document.activeElement === lastFocusableEl) {
						firstFocusableEl.focus();
						e.preventDefault();
					}
				}
			}
		});

        if (this.popupOpen) {
            document.body.classList.add('has-popup-open');
            scrollLock.disablePageScroll();
			this.popup.classList.add('is-active');
        }
	}

	closePopup() {

		this.popupOpen = false;

		scrollLock.enablePageScroll();

		document.body.classList.remove('has-popup-open');

		this.popup.classList.remove('is-active');

        if (window.sessionStorage) {
			sessionStorage.setItem('visited', true);
        }
	}
}
import scrollLock from 'scroll-lock';

export default class Menu {
	constructor() {
		this.hamburger = document.querySelector('[data-hamburger]');
		this.closeHamburger = document.querySelector('[data-closehamburger]');
		this.menu = document.querySelector('[data-menu]');
		this.header = document.querySelector('[data-header]');

		this.menuOpen = false;

		this.hamburger.addEventListener('click', (e) => {
			this.toggleMenu();
		});

		this.closeHamburger.addEventListener('click', (e) => {
			this.toggleMenu();
		});


		// let FocusableElements = this.menu.querySelectorAll('li');

		// const firstTarget = FocusableElements[0];
		// const lastTarget = FocusableElements[FocusableElements.length - 1];

		// const firstFocusableEl = firstTarget.firstElementChild;
		// const lastFocusableEl = lastTarget.firstElementChild;

		// window.addEventListener('keydown', (e) => {
		// 	if (window.innerWidth < 992) {
		// 		if (e.key === 'Escape') {
		// 			this.closeMenu();
		// 		}

		// 		if (e.key === 'Tab' || e.key === 9) {
		// 			if (e.shiftKey) {
		// 				/* shift + tab */ if (document.activeElement === firstFocusableEl) {
		// 					lastFocusableEl.focus();
		// 					e.preventDefault();
		// 				}
		// 			} /* tab */ else {
		// 				if (document.activeElement === lastFocusableEl) {
		// 					firstFocusableEl.focus();
		// 					e.preventDefault();
		// 				}
		// 			}
		// 		}
		// 	}
		// });
	}

	toggleMenu() {
		this.menuOpen ? this.closeMenu() : this.openMenu();
	}

	openMenu() {
		this.menuOpen = true;

		scrollLock.disablePageScroll();

		this.menu.classList.add('is-active');
		this.header.classList.add('has-open-menu');

		this.hamburger.classList.add('is-active');
		this.hamburger.setAttribute('aria-expanded', true);
		this.hamburger.setAttribute('aria-label', 'Sluit het menu');
	}

	closeMenu() {
		if (!this.menuOpen) {
			return new Promise((resolve) => {
				resolve();
			});
		}

		this.menuOpen = false;

		scrollLock.enablePageScroll();

		this.menu.classList.remove('is-active');
		this.header.classList.remove('has-open-menu');

		this.hamburger.classList.remove('is-active');
		this.hamburger.setAttribute('aria-expanded', false);
		this.hamburger.setAttribute('aria-label', 'Open het menu');
	}
}

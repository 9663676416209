export default class StackCards {
	static init() {
        const stackWrappers = document.querySelectorAll('.js-stack');
        stackWrappers.forEach(stack => new StackCards(stack) );
    }

    constructor(stack) {
        this.stack = stack;

        if (!this.stack) {
            return;
        }

        [...this.stack.children].reverse().forEach(i => this.stack.append(i));

        this.stack.addEventListener('click', this.swapCards);

	}

    swapCards(e) {
        const stack = document.querySelector('.js-stack');
        const card = document.querySelector('.stack-card:last-child');

        if(e.target !== card) return;

        card.style.animation = 'swap 700ms forwards';

        setTimeout(() => {
            card.style.animation = '';
            stack.prepend(card);
        }, 500);
    }


}
export default class Video {
	static init() {
        const videoWrapper = document.querySelectorAll('.c-video');
        videoWrapper.forEach(video => new Video(video) );
    }

    constructor(video) {
        this.video = video;

        if (!this.video) {
            return;
        }

        const tag = document.createElement('script');

        tag.src = "https://www.youtube.com/iframe_api";

        const firstScriptTag = document.getElementsByTagName('script')[0];
        firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);

        const el = this.video.querySelector('iframe');

        window.onYouTubeIframeAPIReady = function () {
            const player = new YT.Player( el, {
                events: {
                    'onReady': onPlayerReady,
                    // 'onStateChange': onPlayerStateChange
                }
            });
        }

        function onPlayerReady(event) {
            event.target.playVideo();

        }

        // function onPlayerStateChange(event) {

        // }
	}
}


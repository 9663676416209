const Flickity = require('flickity');
require('flickity-imagesloaded');

export default class Slider {
	static init() {
        const gallerySliders = document.querySelectorAll('.c-slider');
        gallerySliders.forEach(slider => new Slider(slider) );
    }

    constructor(slider) {
        this.slider = slider;

        if (!this.slider) {
            return;
        }

        const flkty = new Flickity( this.slider, {
            cellAlign: 'center',
            contain: true,
            prevNextButtons: false,
            pageDots: false,
            wrapAround: true,
            imagesLoaded: true,
            initialIndex: 1,
			autoPlay: 2000,
			pauseAutoPlayOnHover: false,
        });

        flkty.on('pointerUp', function (event, pointer) {
            flkty.player.play();
        });
	}
}